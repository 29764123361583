import React  from "react"
import PropertiesHero from '../components/properties/PropertiesHero';
import { graphql } from "gatsby"
import Seo from "../components/seo"

const PropertyPostTemplate = ({data, pageContext, next, previous}) => {
  const seo = data.seo.seo;

   
  return (
    
    <div>
       <Seo title={seo.title} description={seo.metaDesc} />
        <PropertiesHero data={data} />      
    </div>
  )
}

export default PropertyPostTemplate

export const pageQuery = graphql`
  query PropertyById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    

    seo: wpRetailProperty(id: { eq: $id })  {
      seo {
        metaDesc
        title
        metaRobotsNofollow
        metaRobotsNoindex
        metaKeywords
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          localFile {
            id
          }
        }
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        twitterTitle
        twitterDescription
      }
    }
    

    # selecting the current post by id
    property: wpRetailProperty(id: { eq: $id }) {
      id
      title
      acf_retail_properties {
        size
        terms
        price
        mainDescription
        agentUrl
        images {
          image{
            localFile{
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpListing(id: { eq: $previousPostId }) {
      uri
      title
      slug
    }

    # this gets us the next post by id (if it exists)
    next: wpListing(id: { eq: $nextPostId }) {
      uri
      title
      slug
    }
  }
`
