import React, {useState, createRef} from 'react'
import {GatsbyImage} from 'gatsby-plugin-image';
import styled from 'styled-components';
import parse from 'html-react-parser'
import ButtonExternal from '../../components/global/ButtonExternal';
// import Clock from '../../assets/clock.inline.svg';
// import Phone from '../../assets/phone.inline.svg';
// import Marker from '../../assets/marker.inline.svg';
// import ProfileEditorForm from './ProfileEditorForm';
// import {GrFacebook} from 'react-icons/gr'
// import {GrTwitter} from 'react-icons/gr'
// import {GrInstagram} from 'react-icons/gr'
// import {GrYoutube} from 'react-icons/gr'
// import {GrContactInfo} from 'react-icons/gr'
// import ClaimBusinessModal from "./ClaimBusinessModal"
import RellaxWrapper from 'react-rellax-wrapper'
import {BsArrowDown} from 'react-icons/bs'

const Hero = styled.section`
width:100%;
position:relative;
background:var(--teal);
overflow:hidden;
padding:2rem 0;

    @media only screen and (min-width: 600px) {
        display:flex;
        padding:4rem 0;
    }



    @media only screen and (min-width: 1024px) {
    background:var(--black);
    min-height:750px;
    height:calc(100vh - var(--headerHeight));
    height: calc((var(--vh, 1vh) * 100) - var(--headerHeight) );
    }



    @keyframes slide-bottom {
        0% {
            -webkit-transform: translateY(0);
                    transform: translateY(0);
        }
        100% {
            -webkit-transform: translateY(10px);
                    transform: translateY(10px);
        }
    }
    .scroller{
    position:absolute;
    right:1rem;
    bottom:2rem;
    color:var(--white);
    z-index:10;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    display:flex;
    align-items:center;
    justify-content:center;
    animation: slide-bottom 0.75s cubic-bezier(0.895, 0.030, 0.685, 0.220) infinite alternate both;

        span{
        margin-bottom:1rem;
        font-family:var(--font-heading);
        text-transform:uppercase;
        font-size:1.4rem;
        }
        svg{
            font-size:2rem;
        }
    }

    .left{
    padding:0 2rem;
    color:var(--black);
    width:100%;

        @media only screen and (min-width: 600px) {    
        width:50%;
        }

        @media only screen and (min-width: 1024px) {
        color:var(--white);
        position: absolute;
        top:0;
        left:0;
        width:50%;
        height:100%;
        display:flex;
        justify-content:center;
        flex-wrap:wrap;
        flex-direction: column;
        padding:0 5rem;
        }

        .info{
        z-index:10;
        margin-top: 30vh;

            @media only screen and (min-width: 1024px) {
                margin-top: 0vh;
            }            

        }        


    }
    .image-wrap{
    width: 100%;
    height: 30vh;
    position: absolute;
    top: 0;
    left:0;
    opacity:1;

        @media only screen and (min-width: 1024px) {
        opacity:1;
        height: 100%;
        }

        .gatsby-image-wrapper{
        position:absolute;
        width:100%;
        height:100%;
        

            &:before{
            content:"";
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background:rgba(0,0,0,.1);
            z-index:1;
            }

       

            img{
            width:100%;
            height:100%;
            object-fit:cover;
            opacity: 1 !important;
                @media only screen and (min-width: 1024px) {
                opacity:1 !important;
                }
            }
        }

    }

    .right{
    padding:2rem 2rem 0rem 2rem;
    width:100%;

        &.l-in{
        padding:2rem 2rem 4rem 2rem;

            @media only screen and (min-width: 600px) {    
                padding:2rem;
            }

            @media only screen and (min-width: 1024px) {
            padding:2rem 8rem;
            }
        }
    

        @media only screen and (min-width: 600px) {    
        padding:0 2rem;
        width:50%;
        margin-top: 30vh;
        }
    

        @media only screen and (min-width: 1024px) {
        padding:0 8rem;
        background:var(--teal);
        width:50%;
        color:var(--white);
        height:100%;
        position: absolute;
        top:0;
        right:0;
        display:flex;
        flex-wrap:wrap;
        justify-content:center;
        flex-direction:column;
        margin-top: 0vh;
        }


        .heading--xl{
        margin-bottom:1rem;
        font-size:4rem;
        }

        .text{
        line-height:1.5rem;
        font-size:0.9rem;
        margin-bottom:1rem;
            
            @media only screen and (min-width: 1024px) {            
                margin-bottom:4rem;
            }
        }

      

        .price,
        .size{
        font-family:var(--font-bold);
        }

        .price{
        font-size:1.2rem;
        }

        .price,
        .size,
        .terms,
        .desc{
        position: relative;
        display:block;
        line-height:1.5rem;
        margin-bottom:1rem;

            p{
            line-height:1.5rem;
            }

        }

        .desc{
        position: relative;
        margin-bottom:2rem;   
        }
        

        
    }
`

export default function HeroDefault(data) {
    const listing = data.data.property;    
    const wrapperStyles = {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
    }
    
   

    return (
        <div>
            <Hero>
                <div className="scroller"><span>Scroll</span> <BsArrowDown /></div>
                <div className="left">
                    <div className="image-wrap">
                        {listing?.acf_retail_properties?.images.map((image) => {
                            return(
                                <RellaxWrapper speed={- 1.25} style={wrapperStyles}>
                                    <GatsbyImage image={image.image.localFile?.childImageSharp?.gatsbyImageData} />
                                </RellaxWrapper>
                            )
                        })
                        
                        }
                    </div>
                </div>
                <div className="right">
                    <div className="info">
                        <h2 className="heading--xl">{listing.title}</h2>  
                        <div className="text">
                            <div className="price"> <div className="price">£{listing.acf_retail_properties.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} p/a</div></div>
                            <div className="size">{listing.acf_retail_properties.size}sqm</div>
                            <div className="terms">{listing.acf_retail_properties.terms}</div>
                            <div className="desc">{parse(listing.acf_retail_properties.mainDescription)}</div>
                            <div className="button_wrap">
                                <ButtonExternal
                                    color="white"
                                    bg="white"
                                    text={`Visit agent website`}
                                    link={listing.acf_retail_properties.agentUrl}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </Hero>
         
        </div>
    )
}
